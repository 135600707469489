/* eslint-disable import/prefer-default-export */
import { ApolloClient, HttpLink, InMemoryCache, createHttpLink } from '@apollo/client'
import fetch from 'isomorphic-fetch'

export const link = createHttpLink({
  fetch,
  uri: process.env.GATSBY_GRAPHQL_ENDPOINT,
})

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({
    uri: 'https://roko.network.s-z.se/graphql',
    fetch,
  }),
})
