module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#f7a700","theme_color":"#f7a700","display":"minimal-ui","icon":"src/assets/images/roko-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a04e4c1264acb39e0672c8bf5a4439f2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://roko.network.s-z.se/graphql","verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"mode":"render-blocking","enableListener":true,"preconnect":["https://fonts.gstatic.com"],"web":[{"name":"Roboto Condensed","file":"https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,700;1,700&display=block"},{"name":"Roboto","file":"https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=block"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManager":{"trackingId":"","cookieName":"gatsby-gdpr-google-tagmanager","dataLayerName":"dataLayer"},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","sv"],"defaultLanguage":"en","redirect":false,"siteUrl":"https://example.com/","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/:lang?/:parent","getLanguageFromPath":true,"excludeLanguages":["en"]},{"matchPath":"/:lang?/:parent/:child","getLanguageFromPath":true,"excludeLanguages":["en"]},{"matchPath":"/:lang?/:parent/:parent/:child","getLanguageFromPath":true,"excludeLanguages":["en"]}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
